import React from 'react';
import { DashboardOutlined, LineChartOutlined, IeOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Site Dashboard',
    key: 'site-dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'Website Data',
    key: 'website-data',
    icon: <IeOutlined />,
    children: [
      {
        title: 'Indicators',
        key: 'indicators',
        url: '/commons/indicators',
      },
      {
        title: 'Associates',
        key: 'associates',
        url: '/commons/associates',
      },
      {
        title: 'Partners',
        key: 'partners',
        url: '/commons/partners',
      },
      {
        title: 'News',
        key: 'news',
        url: '/commons/news',
      },
      {
        title: 'Events',
        key: 'events',
        url: '/commons/events',
      },
    ],
  },
  {
    title: 'Leads',
    key: 'leads',
    url: '/leads',
    icon: <LineChartOutlined />,
  },
];
