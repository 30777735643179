import { useState } from 'react';

export const useEnvContext: any = () => {
  const [selectedEnvironment, setSelectedEnvironment] = useState<'BioReversa' | 'Re-conecta'>('BioReversa');

  return {
    selectedEnvironment,
    setSelectedEnvironment,
  };
};
