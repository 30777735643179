export interface LeadStatusProps {
  value: number;
  name: string;
  description: string;
}

export const statusChartData: LeadStatusProps[] = [
  {
    value: 37,
    name: 'Pendentes',
    description: 'Mensagens recebidas, que ainda não foram visualizadas e respondidas',
  },
  {
    value: 63,
    name: 'Respondidos',
    description: 'Mensagens que já foram respondidas',
  },
];
