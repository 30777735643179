import { CategoryType } from '../categoriesList';

export interface Component {
  name: string;
  title: string;
  url: string;
  categories: CategoryType[];
  keywords: string[];
}

// TODO review and come up with a better approach for urls
// maybe we need to have enum with all routes like we had before?

// TODO change urls according to new dashboard routes and add new NFT components
export const components: Component[] = [
  {
    name: 'Site Dashboard',
    title: 'Site Dashboard',
    url: `/`,
    categories: ['dashboards'],
    keywords: ['dashboard', 'site', 'sites', 'sites dashboard', 'site dashboard', 'painel', 'website'],
  },
  {
    name: 'Indicators',
    title: 'Indicators',
    url: `/commons/indicators`,
    categories: ['website'],
    keywords: ['indicator', 'indicators', 'indicador', 'indicadores', 'website', 'site'],
  },
  {
    name: 'Associates',
    title: 'Associates',
    url: `/commons/associates`,
    categories: ['website'],
    keywords: ['associate', 'associates', 'associado', 'associados', 'website', 'site'],
  },
  {
    name: 'Partners',
    title: 'Partners',
    url: `/commons/partners`,
    categories: ['website'],
    keywords: ['partner', 'partners', 'parceiro', 'parceiros', 'website', 'site'],
  },
  {
    name: 'News',
    title: 'News',
    url: `/commons/news`,
    categories: ['website'],
    keywords: ['news', 'new', 'notícia', 'notícias', 'noticia', 'noticias', 'website', 'site'],
  },
  {
    name: 'Events',
    title: 'Events',
    url: `/commons/events`,
    categories: ['website'],
    keywords: ['events', 'event', 'evento', 'eventos', 'website', 'site'],
  },
  {
    name: 'Leads',
    title: 'Leads',
    url: `/leads`,
    categories: ['leads'],
    keywords: ['leads', 'lead', 'website', 'site'],
  },
];
