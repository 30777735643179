import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartCustomLegend } from '../../common/charts/PieChartCustomLegend';
import { LeadStatusProps, statusChartData } from '@app/constants/statusChartData';
import { Card } from '@app/components/common/Card/Card';

interface StatusCardProps {
  data?: LeadStatusProps[];
}

export const StatusCard: React.FC<StatusCardProps> = ({ data }) => {
  const { t } = useTranslation();

  const chartItems = data ? data : statusChartData;

  const totalValue = chartItems.reduce((acc, curObj) => {
    return acc + curObj.value;
  }, 0);

  const chartData = chartItems.map((item) => ({
    ...item,
    name: t(item.name),
    description: t(item.description),
  }));

  const legendData = chartData.map((item) => ({
    ...item,
    value: `${((item.value / totalValue) * 100).toFixed(2)}%`,
  }));

  return (
    <Card title={t('Leads by Status')} padding={'0 1.25rem 1.875rem'}>
      <PieChartCustomLegend name={t('Leads by Status')} chartData={chartData} legendData={legendData} height="300px" />
    </Card>
  );
};
