import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from '@app/components/common/Carousel/Carousel';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { ViewAll } from '@app/components/nft-dashboard/common/ViewAll/ViewAll';
import { NewsCard } from './news-card/NewsCard';
import { getLastNews } from '@app/api/siteDashboard.api';
import { NewsModel } from '@app/domain/commons/NewsModel';
import { useResponsive } from '@app/hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import * as S from './LastNews.styles';

interface LastNewsProps {
  news?: NewsModel[];
}

export const LastNews: React.FC<LastNewsProps> = ({ news }) => {
  const [lastNews, setLastNews] = useState<NewsModel[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mobileOnly, isTablet } = useResponsive();

  useEffect(() => {
    if (news) setLastNews(news);
    else
      getLastNews().then((result) => {
        setLastNews(result);
      });
  }, [news]);

  const cards = useMemo(() => {
    return {
      mobile: lastNews.slice(0, 3).map((news) => <NewsCard key={news.id} newsItem={news} />),
      tablet: lastNews.map((news) => (
        <div key={news.id}>
          <S.CardWrapper>
            <NewsCard newsItem={news} />
          </S.CardWrapper>
        </div>
      )),
    };
  }, [lastNews]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>();

  // This will avoid showing duplicated elements when less than 3 files are present
  const slidesToShow = useMemo(() => {
    switch (lastNews.length) {
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 3;
    }
  }, [lastNews]);

  return (
    <>
      <NFTCardHeader title={t('Last News')}>
        {isTablet && (
          <Row align="middle">
            <Col>
              <ViewAll bordered={false} onClick={() => navigate('/commons/news')} />
            </Col>

            <Col>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickPrev()}>
                <LeftOutlined />
              </S.ArrowBtn>
            </Col>

            <Col>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickNext()}>
                <RightOutlined />
              </S.ArrowBtn>
            </Col>
          </Row>
        )}
      </NFTCardHeader>

      <S.SectionWrapper>
        {mobileOnly && cards.mobile}

        {isTablet && lastNews.length > 0 && (
          <Carousel
            ref={sliderRef}
            slidesToShow={slidesToShow}
            responsive={[
              {
                breakpoint: 1900,
                settings: {
                  slidesToShow: slidesToShow,
                },
              },
            ]}
          >
            {cards.tablet}
          </Carousel>
        )}
      </S.SectionWrapper>

      {mobileOnly && (
        <S.ViewAllWrapper>
          <ViewAll bordered={false} onClick={() => navigate('/commons/news')} />
        </S.ViewAllWrapper>
      )}
    </>
  );
};
