import { NewsModel } from '@app/domain/commons/NewsModel';

export const getLastNews = (): Promise<NewsModel[]> => {
  return new Promise((res) => {
    res([
      {
        id: 1,
        title: 'Novo Gestor',
        subtitle: 'Cupidatat adipisicing dolore labore magna est proident laboris elit voluptate et minim aliquip.',
        text: 'Aute anim esse deserunt culpa officia reprehenderit magna nisi ut enim esse dolore aute ipsum.',
        author: 'John Doe',
        image_content_type: 'image/jpeg',
        image_size: '78655',
        image_thumbnail_file_size: '32548',
        image_thumbnail_url: '/images/news/manager.jpg',
        image_url: '/images/news/manager.jpg',
        published_at: '2023-10-01',
        user_id: 1,
        user: {
          id: 1,
          name: 'John Doe',
          avatar_thumbnail_url: null,
          avatar_url: null,
          email: null,
          is_active: 1,
          is_verified: 0,
          last_login_at: null,
          role_id: 1,
          username: 'john.doe',
        },
      },
      {
        id: 2,
        title: 'Nova Legislação',
        subtitle: 'Sunt ea ut veniam eu deserunt.',
        text: 'Excepteur do excepteur consequat dolor ad laboris eu tempor mollit est Lorem culpa cupidatat.',
        author: 'Jack Ryan',
        image_content_type: 'image/jpeg',
        image_size: '78655',
        image_thumbnail_file_size: '32548',
        image_thumbnail_url: '/images/news/law.jpg',
        image_url: '/images/news/law.jpg',
        published_at: '2023-10-12',
        user_id: 1,
        user: {
          id: 1,
          name: 'John Doe',
          avatar_thumbnail_url: null,
          avatar_url: null,
          email: null,
          is_active: 1,
          is_verified: 0,
          last_login_at: null,
          role_id: 1,
          username: 'john.doe',
        },
      },
      {
        id: 3,
        title: 'Feira de Resíduos',
        subtitle: 'Do eiusmod aute sunt sunt reprehenderit excepteur amet in cillum.',
        text: 'Enim anim exercitation ex proident cillum ea occaecat eu amet incididunt dolor amet reprehenderit enim.',
        author: 'Mark Ben',
        image_content_type: 'image/jpeg',
        image_size: '78655',
        image_thumbnail_file_size: '32548',
        image_thumbnail_url: '/images/news/waste.jpg',
        image_url: '/images/news/waste.jpg',
        published_at: '2023-10-12',
        user_id: 1,
        user: {
          id: 1,
          name: 'John Doe',
          avatar_thumbnail_url: null,
          avatar_url: null,
          email: null,
          is_active: 1,
          is_verified: 0,
          last_login_at: null,
          role_id: 1,
          username: 'john.doe',
        },
      },
      {
        id: 4,
        title: 'Novo Parceiro',
        subtitle: 'Do nisi nostrud duis proident veniam ex irure ea consequat ullamco anim consequat nisi duis.',
        text: 'Consequat non velit incididunt deserunt ad.',
        author: 'Ron Jorge',
        image_content_type: 'image/jpeg',
        image_size: '78655',
        image_thumbnail_file_size: '32548',
        image_thumbnail_url: '/images/news/partner.jpg',
        image_url: '/images/news/partner.jpg',
        published_at: '2023-10-12',
        user_id: 1,
        user: {
          id: 1,
          name: 'John Doe',
          avatar_thumbnail_url: null,
          avatar_url: null,
          email: null,
          is_active: 1,
          is_verified: 0,
          last_login_at: null,
          role_id: 1,
          username: 'john.doe',
        },
      },
      {
        id: 5,
        title: 'Curso de Reciclagem',
        subtitle: 'Consectetur in labore laboris laborum laborum commodo tempor.',
        text: 'Elit laboris sunt minim voluptate.',
        author: 'Phil Bard',
        image_content_type: 'image/jpeg',
        image_size: '78655',
        image_thumbnail_file_size: '32548',
        image_thumbnail_url: '/images/news/recycle.jpg',
        image_url: '/images/news/recycle.jpg',
        published_at: '2023-10-12',
        user_id: 1,
        user: {
          id: 1,
          name: 'John Doe',
          avatar_thumbnail_url: null,
          avatar_url: null,
          email: null,
          is_active: 1,
          is_verified: 0,
          last_login_at: null,
          role_id: 1,
          username: 'john.doe',
        },
      },
    ]);
  });
};
