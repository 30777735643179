export type CategoryType = 'dashboards' | 'website' | 'leads';

interface Category {
  name: CategoryType;
  title: string;
}

export const categoriesList: Category[] = [
  {
    name: 'dashboards',
    title: 'Dashboards',
  },
  {
    name: 'website',
    title: 'Website',
  },
  {
    name: 'leads',
    title: 'Leads',
  },
];
