import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../common/Card/Card';
import { ReceivedChart } from './ReceivedChart';
import styled from 'styled-components';

interface ReceivedCardProps {
  items?: number[];
}

export const ReceivedCard: React.FC<ReceivedCardProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <ReceivedCardStyled id="activity" title={t('Leads Generated this Week')} padding={0}>
      <ReceivedChart data={items ? items : [3, 14, 35, 22, 34, 20, 15]} />
    </ReceivedCardStyled>
  );
};

const ReceivedCardStyled = styled(Card)`
  height: 100%;
`;
