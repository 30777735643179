import React, { useState } from 'react';
import { Button as BaseButton } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { useTranslation } from 'react-i18next';
import { useEnvContext } from '@app/hooks/useEnvContext';

export const EnvironmentButton: React.FC = (props) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { t } = useTranslation();
  const { selectedEnvironment, setSelectedEnvironment } = useEnvContext();

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Modal
        title={t('Select the Environment')}
        centered
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        size="small"
        footer={false}
      >
        <EnvWrapper>
          <BaseButton
            type="primary"
            disabled={selectedEnvironment === 'BioReversa'}
            onClick={() => setSelectedEnvironment('BioReversa')}
            {...props}
          >
            BioReversa
          </BaseButton>
          <BaseButton
            type="primary"
            disabled={selectedEnvironment === 'Re-conecta'}
            onClick={() => setSelectedEnvironment('Re-conecta')}
            {...props}
          >
            Re-conecta
          </BaseButton>
        </EnvWrapper>
      </Modal>
      <Button
        type="ghost"
        icon={<EnvIcon />}
        $isDark={theme === 'dark'}
        onClick={() => setIsModalVisible(true)}
        {...props}
      >
        {selectedEnvironment}
      </Button>
    </>
  );
};

const Button = styled(BaseButton)<{ $isDark: boolean }>`
  color: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'black']};
  background: ${(props) => BASE_COLORS[props.$isDark ? 'black' : 'white']};
  border-radius: 50px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => BASE_COLORS[props.$isDark ? 'black' : 'white']};
    background: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'black']};
  }
`;

const EnvWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const EnvIcon = styled(GlobalOutlined)`
  font-size: 1.5rem;
  vertical-align: middle;
`;
