import { WithChildrenProps } from '@app/types/generalTypes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export const PageTitle: React.FC<WithChildrenProps> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>
        {children} | {t('CMS BioReversa')}
      </title>
    </Helmet>
  );
};
