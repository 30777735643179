import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LastNews } from '@app/components/site-dashboard/last-news/LastNews';
import { ReceivedCard } from '@app/components/site-dashboard/receivedCard/ReceivedCard';
import { StatusCard } from '@app/components/site-dashboard/statusCard/StatusCard';
import { httpApi } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';
import { SiteDashboardModel } from '@app/domain/commons/SiteDashboardModel';
import { statusChartData } from '@app/constants/statusChartData';

const SiteDashboardPage: React.FC = () => {
  const { isTablet, isDesktop } = useResponsive();
  const { t } = useTranslation();

  const [siteDashboard, setSiteDashboard] = useState<SiteDashboardModel>({
    leads_by_status: statusChartData,
    last_news: [],
    week_received_leads: [0, 0, 0, 0, 0, 0, 0],
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fetching the API
    httpApi
      .get(`/site/dashboard`)
      .then(({ data }) => {
        setSiteDashboard(data.data);
      })
      .catch((err) => {
        if (err.options.status === 401) {
          notificationController.error({ message: err.message });
          navigate('/auth/lock');
        }
      });
  }, [navigate]);

  const desktopLayout = (
    <Row gutter={[60, 60]}>
      <Col span={24}>
        <LastNews news={siteDashboard.last_news} />
      </Col>

      <Col id="activity" xl={24} xxl={12}>
        <ReceivedCard items={siteDashboard.week_received_leads} />
      </Col>

      <Col id="health" xl={24} xxl={12}>
        <StatusCard data={siteDashboard.leads_by_status} />
      </Col>
    </Row>
  );

  const mobileAndTabletLayout = (
    <Row gutter={[20, 24]}>
      <Col span={24}>
        <LastNews news={siteDashboard.last_news} />
      </Col>

      <Col id="activity" xs={24} md={12} order={(isTablet && 8) || 0}>
        <ReceivedCard items={siteDashboard.week_received_leads} />
      </Col>

      <Col id="health" xs={24} md={12} order={(isTablet && 9) || 0}>
        <StatusCard data={siteDashboard.leads_by_status} />
      </Col>
    </Row>
  );

  return (
    <>
      <PageTitle>{t('Site Dashboard')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default SiteDashboardPage;
