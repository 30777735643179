import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { NewsModel } from '@app/domain/commons/NewsModel';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as S from './NewsCard.styles';

interface NewsCardProps {
  newsItem: NewsModel;
}

export const NewsCard: React.FC<NewsCardProps> = ({ newsItem }) => {
  const { isTablet } = useResponsive();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const tabletLayout = (
    <>
      <S.InfoHeader>
        <S.InfoText>
          {t('by')} {newsItem.author}
        </S.InfoText>
        <S.InfoText>{newsItem.text.slice(0, 110)}</S.InfoText>
      </S.InfoHeader>

      <S.InfoFooter>
        <S.CurrentBidWrapper>
          <S.Status>{moment(newsItem.published_at).format('DD/MM/YYYY')}</S.Status>
        </S.CurrentBidWrapper>
      </S.InfoFooter>
    </>
  );

  const mobileLayout = (
    <>
      <S.InfoRow>
        <S.InfoText>
          {t('by')} {newsItem.author}
        </S.InfoText>
      </S.InfoRow>

      <S.InfoRow>
        <S.Status>{moment(newsItem.published_at).format('DD/MM/YYYY')}</S.Status>
      </S.InfoRow>
    </>
  );

  return (
    <S.Card
      padding={0}
      $img={newsItem.image_thumbnail_url || '/images/no-image.jpg'}
      onClick={() => navigate('/commons/news')}
      style={{ cursor: 'pointer' }}
    >
      <S.NewsImage src={newsItem.image_thumbnail_url || '/images/no-image.jpg'} alt="Image" />
      <S.NewsInfo>
        <S.InfoRow>
          <S.Title>{newsItem.title}</S.Title>
        </S.InfoRow>
        {isTablet ? tabletLayout : mobileLayout}
      </S.NewsInfo>
    </S.Card>
  );
};
